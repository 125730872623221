// Reemplaza 'TU_CLAVE_DE_API' con tu clave de API de Freesound
const apiKey = '2XIFtexaFHK85VeDRqD1oHivj64qhRgDFWNexVdG';

// ID de usuario de Freesound (reemplaza con tu ID de usuario)
const userId = 'EmilioOcelotl';

// URL base de la API de Freesound
const apiUrl = 'https://freesound.org/apiv2';

// Endpoint para obtener sonidos de un usuario específico
const endpoint = `/users/${userId}/sounds/`;

// Construye la URL completa para la solicitud
const url = `${apiUrl}${endpoint}?token=${apiKey}`;

export {apiKey, url}
